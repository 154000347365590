import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/administrator.css";
import "../../../styles/admincontact.css";
// Refer Contact administrator
import Sidepanel from "../common/sidepanel";
// Import components
import BottomPanel from "../common/bottomPanel";
import axios from "axios";
// Icons
import { MdAdd } from "react-icons/md";
import { CiLocationArrow1 } from "react-icons/ci";
import { postMessage } from "../../../api/apiService";
import { broadcastMessage } from "../../../api/apiService";

const Messaging = () => {
  const navigate = useNavigate();
  const [adminMessage, setAdminMessage] = useState("");
  // const [userId, setUserId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [chatName, setChatName] = useState("   ");
  const [chatId, setChatId] = useState("");
  const [data, setData] = useState([]);
  const [adminMessages, setAdminMessages] = useState([]);
  const [error, setError] = useState("");
  const [messageSend, setMessageSend] = useState(0);
  const containerRef = useRef(null);
  const endOfMessageRef = useRef(null);
  const [isBroadcast, setIsBroadcast] = useState(true);
  const [imageBlob, setImageBlob] = useState(null);

  // if (localStorage.getItem("userID") !== userId) {
  //   setUserId(localStorage.getItem("userID"));
  // }

  const userId = localStorage.getItem("userID");

  //choose broadcast message or not
  const messageChooser = () => {
    if (isBroadcast === true) {
      sendBroadcastMessage();
    } else {
      sendMessage();
    }
  };

  /* handle file change function################
  this function is used to handle the file change event
  first check if there is file avaialble or not and then check the file type if file type is image(png) then create new reader object. 
  then set imageBlob value to the reader.result
  */
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "image/png") {
      const reader = new FileReader();
      reader.onload = () => {
        const imageBlob = reader.result;
        setImageBlob(imageBlob);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("Please upload a png image");
    }
  };

  // Send message
  /* this will send message to the driver. take parameters of 
    userId, driverId, adminMessage, image
    image is base64 string
  */
  const sendMessage = async (e) => {
    try {
      const image = [
        {
          imageBlob:
            imageBlob ||
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==",
        },
      ];
      const response = await postMessage(userId, driverId, adminMessage, image);
      if (response.success === true) {
        setMessageSend(messageSend + 1);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchMessageList = async () => {
      try {
        const response = await axios.post(
          "https://greenland-backend.azurewebsites.net/api/v1.0/access/chat/list",
          { userid: localStorage.getItem("userID") },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log(response);
        setData(response.data.ChatList);
        // if (response.data.success == true) {
        //   const driverDetailsArray = Array.isArray(response.data.ChatList)
        //     ? response.data.ChatList
        //     : [response.data.ChatList];
        //   setDriverDetails(driverDetailsArray);
        // } else {
        //   console.warn("There is a error in administrator messaging ");
        // }
      } catch (err) {
        console.error(err);
        console.log(localStorage);
      }
    };

    fetchMessageList();
  }, []);

  // Chat Click
  const handleChatClick = async (chatId, chatName) => {
    if (chatName && chatId) {
      setChatName(chatName);
      setChatId(chatId);
      setDriverId(chatId);
    } else {
      console.log("Please check chat id and chat name");
    }

    if (chatId) {
      setDriverId(chatId);
    }

    if (endOfMessageRef.current) {
      endOfMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChatClickMob = async (chatId, chatName) => {
    if (chatName && chatId) {
      setChatName(chatName);
      setChatId(chatId);
    }

    direct();
  };

  const direct = async () => {
    navigate(`${chatId}`);
  };

  // Call side efect when change of the chat ID
  // using for fetch all messages of the driver
  useEffect(() => {
    const getAllDriverMessages = async (driverId) => {
      try {
        const response = await axios.post(
          "https://greenland-backend.azurewebsites.net/api/v1.0/access/chat/messages",
          {
            driver_id: driverId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.ChatList === undefined) {
          console.log("undefined");
        } else {
          setAdminMessages(response.data.ChatList);
          setError("");
        }

        if (endOfMessageRef.current) {
          endOfMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }

        return response.data;
      } catch (err) {
        console.error(err);
        console.log("There are no messages to show");
        setError("No Previous Messages");
        setAdminMessages([]);
      }
    };
    getAllDriverMessages(chatId);
  }, [chatId, messageSend]);

  useEffect(() => {
    if (endOfMessageRef.current) {
      endOfMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const sendBroadcastMessage = async () => {
    try {
      console.log(userId);
      await broadcastMessage(userId, adminMessage);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="administrator__contact__container">
        <Sidepanel />
        <div className="messaging__desk">
          <div className="administrator__choose__driver">
            <h1 className="title">CONTACT&nbsp;DRIVER</h1>

            <div className="administrator__message__choose">
              <button
                className={`heading-3 ${
                  isBroadcast === true
                    ? "broadcast-active"
                    : "broadcast-deactive"
                }`}
                onClick={() => setIsBroadcast(true)}
              >
                Broadcast
              </button>
              {data.map((chat, index) => (
                <button
                  key={index}
                  onClick={() => {
                    handleChatClick(chat.ChatID, chat.ChatName);
                    setIsBroadcast(false);
                  }}
                >
                  <h4 className="heading-3">{chat.ChatName}</h4>
                </button>
              ))}
            </div>
          </div>

          {isBroadcast === true ? (
            <div className="administrator-messages">
              <div className="amc-chatName">
                <h4 className="heading-1 ">Broadcast Message to All drivers</h4>
              </div>
              <div
                ref={containerRef}
                className="administrator__messaging__container"
              >
                <p className="text">{error}</p>
                {adminMessages
                  .slice()
                  .reverse()
                  .map((message) =>
                    message && message.ChatSender && message.Message ? (
                      <div
                        key={message.ChatID}
                        className={`${
                          message.ChatSender === "DRIVER"
                            ? "broadcast"
                            : "sender"
                        }`}
                      >
                        <p className="text">{message.Message}</p>
                        <div ref={endOfMessageRef} />
                      </div>
                    ) : null
                  )}
              </div>
              <div className="message__writter__container__desk">
                <label className="m__wm__button__send">
                  <MdAdd size={30} />
                  <input
                    type="file"
                    accept="image/png"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </label>
                <input
                  className="message__writter__desk text"
                  aria-autocomplete="inline"
                  autoCorrect="on"
                  spellCheck="true"
                  contentEditable="true"
                  placeholder="Type Your Message Here"
                  onChange={(e) => setAdminMessage(e.target.value)}
                />
                <button
                  onClick={() => messageChooser()}
                  className="m__wm__button__send"
                >
                  <CiLocationArrow1 size={30} />
                </button>
              </div>
            </div>
          ) : (
            <div className="administrator-messages">
              <div className="amc-chatName">
                <h4 className="heading-1 ">{chatName}</h4>
              </div>
              <div
                ref={containerRef}
                className="administrator__messaging__container"
              >
                <p className="text">{error}</p>
                {adminMessages
                  .slice()
                  .reverse()
                  .map((message) =>
                    message && message.ChatSender && message.Message ? (
                      <div
                        key={message.ChatID}
                        className={`${
                          message.ChatSender === "DRIVER"
                            ? "reciever"
                            : "sender"
                        }`}
                      >
                        <p className="text">{message.Message}</p>
                        <div ref={endOfMessageRef} />
                      </div>
                    ) : null
                  )}
              </div>
              <div className="message__writter__container__desk">
              <label className="m__wm__button__send">
                  <MdAdd size={30} />
                  <input
                    type="file"
                    accept="image/png"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </label>
                <input
                  className="message__writter__desk text"
                  aria-autocomplete="inline"
                  autoCorrect="on"
                  spellCheck="true"
                  contentEditable="true"
                  placeholder="Type Your Message Here"
                  onChange={(e) => setAdminMessage(e.target.value)}
                />
                <button
                  onClick={() => messageChooser()}
                  className="m__wm__button__send"
                >
                  <CiLocationArrow1 size={30} />
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="administrator__contact__container__mob">
          <h1 className="title">CONTACT DRIVER</h1>
          <div className="administrator__message__choose__mob">
            {data.map((chat, index) => (
              <button
                key={index}
                onClick={() => handleChatClickMob(chat.ChatID, chat.ChatName)}
              >
                <h4 className="heading-3">{chat.ChatName}</h4>
              </button>
            ))}
          </div>
        </div>
        <BottomPanel />
      </div>
    </>
  );
};

export default Messaging;
