import React from "react";
import "../../../styles/administrator.css";
import { useState } from "react";
import { registerDriver } from "../../../api/apiService";
import { createDriverValidation } from "../../../provider/validationProvider";
import { ToastContainer, toast, Bounce } from "react-toastify";

const DriverRegistration = () => {
  const [driverName, setDriverName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.warning("Please Enter Password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    const response = await createDriverValidation(
      driverName,
      contactNumber,
      password,
      confirmPassword
    );

    if (response === true) {
      createDriver();
      toast.success("Driver Created Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const createDriver = async () => {
    const loading = toast.loading("Creating a Driver...");

    try {
      const response = await registerDriver(
        driverName,
        contactNumber,
        password
      );
      console.log(response)

      if (response.success === false) {
        toast.update(loading, {
          render: "Driver Already Exists",
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }

      if (response.success === true) {
        toast.update(loading, {
          render: "Create new Driver Successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="driver__registration__container">
        <h3 className="heading-2">DRIVER REGISTRATION</h3>
        <form className="driver__registration_details">
          {/* Driver Name */}
          <p className="d__reg__driver__name heading-3">Driver Name</p>
          <input
            type="text "
            className="input"
            id="driver_name"
            required
            onChange={(e) => setDriverName(e.target.value)}
          />
          {/* Contact Number */}
          <p className="d__reg__driver__contact heading-3">Contact Number</p>
          <input
            type="text"
            className="input"
            id="contact_number"
            required
            onChange={(e) => setContactNumber(e.target.value)}
          />
          {/* Phone Number */}
          <p className="d__reg__driver__password heading-3">Password</p>
          <input
            type="password"
            className="input"
            id="password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* Confirm Password */}
          <p className="d__reg__driver__confirm__password heading-3">
            Confirm Password
          </p>
          <input
            type="password"
            className="input"
            id="confirm_password"
            required
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button className="button button-1 " onClick={handleSubmit}>
            Register Driver
          </button>
        </form>
        <ToastContainer className="notifications" />
      </div>
      {/* Driver Registration mobile */}
      <div className="driver__registration__mobile__container">
        <h2 className="heading-2">Driver Registration</h2>
        <form
          action="
        "
          className="dr__reg__form__mob"
        >
          <input
            type="text"
            className="input"
            placeholder="Drive Name"
            id="driver_name_mob"
            onChange={(e) => setDriverName(e.target.value)}
          />
          <input
            type="text"
            className="input"
            placeholder="Contact Number"
            id="contact_number_mob"
            onChange={(e) => setContactNumber(e.target.value)}
          />
          <input
            type="text"
            className="input"
            placeholder="Password"
            id="password_mob"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="text"
            className="input"
            placeholder="Confirm Password"
            id="confirm_password_mob"
            onChange={(e) => confirmPassword(e.target.value)}
          />
          <button className="button button-1" onClick={handleSubmit}>
            Submit
          </button>
        </form>
        <ToastContainer className="notifications" />
      </div>
    </>
  );
};

export default DriverRegistration;
