import React, { useState } from "react";
import {
  getDrivers,
  updateDriver,
  deactivateDriver,
} from "../../../api/apiService";
import { updateDriverValidation } from "../../../provider/validationProvider";
import { ToastContainer, toast, Bounce } from "react-toastify";

const EditDriver = () => {
  // token

  // states
  const [driverContactNumber, setDriverContactNumber] = useState("");
  const [driverName, setDriverName] = useState("");
  // Update Values
  const [newDriverName, setNewDriverName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [driverId, setDriverId] = useState("");

  // Select Driver
  const selectDriver = async (e) => {
    e.preventDefault();
    try {
      const data = await getDrivers();
      const drivers = data.driverInfo;
      console.log(drivers);

      // Check is driver available in the database
      const isContactNumberExist = (driverContactNumber, drivers) => {
        if (
          drivers.some((driver) => driver.ContactNo === driverContactNumber)
        ) {
         
          return drivers.some(
            (driver) => driver.ContactNo === driverContactNumber
          );
        } else {
          console.log("driver not found");
          toast.warn("Driver Not Found", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      };

      if (isContactNumberExist(driverContactNumber, drivers)) {
        const driver = drivers.find(
          (driver) => driver.ContactNo === driverContactNumber
        );
        setDriverId(driver.UserID);
        setDriverName(driver.DriverName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Change Driver Infomation
  const changeDriverInfo = async (e) => {
    e.preventDefault();

    try {
      if (newPassword !== newConfirmPassword) {
        alert("Password and Confirm Password should be same");
      }

      updateDriverValidation(newDriverName, newPassword);

      const response = await updateDriver(
        newDriverName,
        driverContactNumber,
        newPassword
      );

      // todo - handle feedback
      console.log(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };


  const removeDriver = async (e) => {
    e.preventDefault();
    try {
      if (driverId !== "") {
        const response = await deactivateDriver(driverId);
        if (response.success === true) {
          console.log("Driver Removed");
          toast.success("Driver Removed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
         
        }
      } else {
        console.log("Driver Cannot be found");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="edit__driver__container">
        <h3 className="heading-2">EDIT / REMOVE DRIVER INFORMATION</h3>
        <form action="" className="edit__driver__details">
          <h5 className="edit__driver__selection heading-3">
            Select Driver by Contact Number
          </h5>

          {/* Selecting the driver */}
          <input
            type="text"
            className="input"
            placeholder="Contact Number"
            onChange={(e) => setDriverContactNumber(e.target.value)}
          />
          <button className="button button-2" onClick={selectDriver}>
            Select Driver
          </button>

          <button
            className="button button-3 e__dr__btn__remove__driver"
            onClick={removeDriver}
          >
            Remove Driver
          </button>

          {/* Update Driver Information */}
          {/* Driver Name */}
          <p className="e__dr__driver__name heading-3">Driver Name</p>
          <input
            type="text"
            className="input"
            placeholder={
              driverName ? `${driverName}` : "Enter Phone Number Find Driver"
            }
            onChange={(e) => setNewDriverName(e.target.value)}
          />

          {/* Driver Contact Number */}
          <p className="e__dr__contact__number heading-3">Contact Number</p>
          <input
            type="text "
            className="input"
            placeholder={
              driverContactNumber
                ? `${driverContactNumber}`
                : "Enter Phone Number to Find Driver"
            }
            value={driverContactNumber}
          />

          <p className="heading-3">New Password</p>
          <input
            type="password"
            className="input"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <p className="heading-3">Confirm Password</p>
          <input
            type="password"
            className="input"
            onChange={(e) => setNewConfirmPassword(e.target.value)}
          />
          <button
            className="button button-2 e__dr__btn__change__info"
            onClick={changeDriverInfo}
          >
            Change Information
          </button>
        </form>
        <ToastContainer />
      </div>

      {/* Change Driver Information mobile */}
      <div className="edit__driver__mobile__container">
        <h2 className="heading-2">Edit/Remove Driver Information</h2>
        <form action="" className="ed__dr__form__mob">
          <p className="text">Select Driver Using Driver's Contact Number</p>
          <input type="text" className="input" placeholder="Contact Number" />
          <div className="ed__dr__form__mob__select">
            <button className="button button-1" onClick={selectDriver}>Select Driver</button>
            <button className="button button-3" onClick={removeDriver}>
              Remove Driver
            </button>
          </div>
          <input type="text" className="input" placeholder="Driver Name" />
          <input type="text" className="input" placeholder="Contact Number" />
          <input type="password" className="input" placeholder="Old Password" />
          <input type="password" className="input" placeholder="New Password" />
          <input
            type="password"
            className="input"
            placeholder="Confirm Password"
          />
          <button className="button button-2">Change Information</button>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default EditDriver;
