
import {toast, Bounce } from "react-toastify";
const createDriverValidation = async (
  driverName,
  contactNumber,
  password,
  confirmPassword
) => {
  if (driverName === null || driverName.trim() === "") {
    toast.warning("Please Enter Driver Name", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
  if (driverName.length < 3) {
    toast.warning("Please Enter Valid Driver Name", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    return false;
  }
  if (contactNumber === null || contactNumber.trim() === "") {
    toast.warning("Please Enter Contact Number", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
  if (contactNumber.length < 9) {
    toast.warning("Please Enter Valid Contact Number", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    return false;
  }
  if (password === null || password.trim() === "") {
    toast.warning("Please Enter Password", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
  if (password.length < 8) {
    toast.warning("Please Enter Valid Password", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    return false;
  }
  if (confirmPassword === null || confirmPassword.trim() === "") {
    toast.warning("Password Doesn't Match", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
  if (confirmPassword !== password) {
    toast.warning("Password Doesn't Match", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    return false;
  } else {
    return true;
  }
};

const updateDriverValidation = async (driverName, password) => {
  try {
    if (driverName === null || driverName.trim() === "") {
      toast.warning("Please Enter Driver Name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return false;
    }
    if (driverName.length < 3) {
      toast.warning("Driver Name must Be more than 3 Charachters", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return false;
    }
    if (password === null || password.trim() === "") {
      toast.warning("Please Enter Password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return false;
    }
    if (password.length < 8) {
      toast.warning("Password must be more than 6 Charachters long", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.warn(error.message);
    throw error;
  }
};
export { createDriverValidation, updateDriverValidation };
